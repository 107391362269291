import { useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

export function SignOutIndex() {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({ returnTo: window.location.origin });
  });

  return <></>;
}

export default withAuthenticationRequired(SignOutIndex);
